import {ranking2022} from "../assets/ranking_2022";
import {ranking2023} from "../assets/ranking_2023";
import {ranking2024} from "../assets/ranking_2024";

export const generalRankings = {
    "2024": ranking2024,
    "2023": ranking2023,
    "2022": ranking2022
};
export type generalRankingKeysType = keyof typeof generalRankings;
export const generalRankingKeys = Object.keys(generalRankings);

export const rankingYears = [2024, 2023, 2022];
export const rankingLastYear = rankingYears[0];
import {ranking2022} from "../assets/ranking_2022";
import {ranking2023} from "../assets/ranking_2023";
import {ranking2024} from "../assets/ranking_2024";

export const generalRankings = {
    "2024": ranking2024,
    "2023": ranking2023,
    "2022": ranking2022
};
export type generalRankingKeysType = keyof typeof generalRankings;
export const generalRankingKeys = Object.keys(generalRankings);

export const rankingYears = [2024, 2023, 2022];
export const rankingLastYear = rankingYears[0];
